import WalletConnectProvider from "@walletconnect/web3-provider";

export const supportedChains = [1, 3, 56, 97];

export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      rpc: {
        // Mainnets
        1: "https://speedy-nodes-nyc.moralis.io/57102c7bc69e6e18c61f9b90/eth/mainnet",
        56: "https://speedy-nodes-nyc.moralis.io/57102c7bc69e6e18c61f9b90/bsc/mainnet",

        // Testnets
        97: "https://speedy-nodes-nyc.moralis.io/57102c7bc69e6e18c61f9b90/bsc/testnet",
        3: "https://speedy-nodes-nyc.moralis.io/57102c7bc69e6e18c61f9b90/eth/ropsten",
      },
      network: "binance",
      networkUrl: `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_ID}`,
    },
  },
};
