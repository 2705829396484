const callCustomToast = (
  title,
  description,
  status,
  onCloseComplete = null,
  toast
) => {
  toast({
    title,
    description,
    status,
    duration: 9000,
    position: "top",
    isClosable: true,
    onCloseComplete,
  });
};

export default callCustomToast;
