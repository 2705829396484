import { useCallback } from "react";
import { useWalletConnectContext } from "../../../context/user";
import { providers } from "ethers";
import { SET_WEB3_PROVIDER, RESET_WEB3_PROVIDER } from "./constants";
const useWalletConnect = (web3Modal) => {
  const { state, dispatch } = useWalletConnectContext();
  const { provider } = state;

  const connect = useCallback(async function () {
    // This is the initial `provider` that is returned when
    // using web3Modal to connect. Can be MetaMask or WalletConnect.
    const provider = await web3Modal.connect();

    // We plug the initial `provider` into ethers.js and get back
    // a Web3Provider. This will add on methods from ethers.js and
    // event listeners such as `.on()` will be different.
    const web3Provider = new providers.Web3Provider(provider);

    const signer = web3Provider.getSigner();
    const address = await signer.getAddress();
    const network = await web3Provider.getNetwork();

    dispatch({
      type: SET_WEB3_PROVIDER,
      provider,
      web3Provider,
      address,
      chainId: network.chainId,
    });
  }, []);

  const disconnect = useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      if (provider?.disconnect && typeof provider.disconnect === "function") {
        await provider.disconnect();
      }
      dispatch({
        type: RESET_WEB3_PROVIDER,
      });
    },
    [provider]
  );

  return { connect, disconnect, state, dispatch };
};

export default useWalletConnect;
