import * as React from "react";

import {
  Box,
  Text,
  chakra,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react";

import { Copyright } from "./Copyright";

export const Logo = (props) => {
  const [white, black] = useToken("colors", ["white", "gray.800"]);
  return (
    <>
      <Box
        pt={4}
        display="flex"
        flexDir={["column", "row", "row", "row"]}
        alignItems="center"
      >
        <a href="https://memecoingen.com">
          <img src="/memecoingen_logo.svg" width="90px" />
        </a>
        <Text ml={4} alignItems="center" display="flex">
          <Copyright></Copyright>
        </Text>
      </Box>
    </>
  );
};
