import * as React from "react";

import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Stack,
} from "@chakra-ui/react";
import {
  FaInstagram,
  FaMedium,
  FaTelegramPlane,
  FaTwitter,
} from "react-icons/fa";

import { Logo } from "./Logo";
import { usePlausible } from "next-plausible";

export const Footer = () => {
  const plausible = usePlausible();

  const click_twitter_btn = () => {
    plausible("CTA", {
      props: {
        section: "Footer",
        btnID: "Twitter CTA",
      },
    });
    plausible("CTA_FooterTwitterBtn");
  };

  const click_telegram_btn = () => {
    plausible("CTA", {
      props: {
        section: "Footer",
        btnID: "Telegram CTA",
      },
    });
  };

  const click_medium_btn = () => {
    plausible("CTA", {
      props: {
        section: "Footer",
        btnID: "Medium CTA",
      },
    });
  };

  const click_tiktok_btn = () => {
    plausible("CTA", {
      props: {
        section: "Footer",
        btnID: "TikTok CTA",
      },
    });
  };

  return (
    <Box
      as="footer"
      role="contentinfo"
      mx="auto"
      maxW="7xl"
      py="12"
      px={{ base: "4", md: "8" }}
    >
      <Stack>
        <Stack
          direction="row"
          spacing="4"
          align="center"
          justify="space-between"
          id="gelogleo"
          flexDir={["column", "row", "row", "row"]}
        >
          <Logo />
          {/* <SocialMediaLinks /> */}
          {/* <Box> */}
          <Box>
            <HStack
              spacing={4}
              display="flex"
              maxW={["300px", "none", "none", "none"]}
            >
              {/* <IconButton
              bg="gold"
              p="4"
              aria-label="bsc"
              icon={<Image src="./bscscan-logo-circle.svg" w="24px" />}
            ></IconButton> */}

              <IconButton
                bg="blue.500"
                borderWidth="medium"
                borderColor="blue.500"
                variant="outline"
                borderRadius="10px"
                p="4"
                aria-label="bsc"
                icon={<FaTelegramPlane color="white" size="24px" />}
                as="a"
                href="https://t.me/joinchat/3YFIbEo4mNk2NDM8"
                onClick={click_telegram_btn}
              ></IconButton>

              <IconButton
                bg="blue.300"
                borderRadius="10px"
                p="4"
                aria-label="bsc"
                icon={<FaTwitter color="white" size="24px" />}
                as="a"
                target="_blank"
                href="https://twitter.com/memecoingen"
                onClick={click_twitter_btn}
              ></IconButton>

              <IconButton
                bg="green.400"
                borderRadius="10px"
                p="4"
                aria-label="bsc"
                icon={<FaMedium color="white" size="24px" />}
                as="a"
                target="_blank"
                href="https://medium.com/memecoingen"
                onClick={click_medium_btn}
              ></IconButton>

              <IconButton
                bg="#EE1C52"
                p="4"
                borderRadius="10px"
                aria-label="bsc"
                icon={<Image fill="white" src="/tiktok.svg" width="20px" />}
                as="a"
                target="_blank"
                href="https://tiktok.com/@memecoingen"
                onClick={click_tiktok_btn}
              ></IconButton>

              <IconButton
                bgGradient="linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)"
                p="4"
                borderRadius="10px"
                aria-label="bsc"
                icon={<FaInstagram color="white" size="28px" />}
                as="a"
                target="_blank"
                href="https://instagram.com/memecoingen"
                onClick={click_tiktok_btn}
              ></IconButton>

              {/* <IconButton
              bg="blue.300"
              p="4"
              aria-label="bsc"
              icon={<Image src="./pancakeswap-cake-logo.svg" w="24px" />}
            ></IconButton> */}
            </HStack>
          </Box>
        </Stack>
        {/* <Copyright alignSelf={{ base: "center", sm: "start" }} /> */}
        <Box textAlign="center" px={[4, 2, 0, 0]} pt={4}>
          <Box mr={1} as="span">
            Works best with{" "}
          </Box>{" "}
          <Box
            as="a"
            href="https://metamask.io"
            fontWeight="bold"
            color="orange"
          >
            {" "}
            MetaMask
          </Box>{" "}
          <Image
            ml={2}
            display="inline-block"
            w="24px"
            src="/metamask.svg"
          ></Image>
        </Box>
      </Stack>
    </Box>
  );
};
