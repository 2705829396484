import * as Sentry from "@sentry/nextjs";

import { Box, Button, Flex, Image, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getEllipsisAddress, getChainData } from "../../lib/utilities";
import { providerOptions, supportedChains } from "./utils/providerOptions";
import useWalletConnect from "./utils/useWalletConnect";
import { useWeb3Account } from "../../context/user";

import { MotionBox } from "../MotionBox";
import Web3Modal from "web3modal";
import {
  ACCOUNT_CHANGED,
  ACCOUNT_CHANGED_DESCRIPTION,
  STATE_INFO,
  SET_ADDRESS,
  NETWORK_CHANGED,
  NETWORK_CHANGED_DESCRIPTION,
  DISCONNECT_EVT,
  ACCOUNTS_CHANGED_EVT,
  CHAIN_CHANGED_EVT,
  COULDNT_OPEN_MODAL_ERROR,
  UNSUPPORTED_NETWORK,
  UNSUPPORTED_NETWORK_DESCRIPTION,
  STATE_WARNING,
} from "./utils/constants";
import callCustomToast from "./utils/callCustomToast";

let web3Modal;
if (typeof window !== "undefined") {
  web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions, // required
  });
}

const refreshWindow = () => {
  window.location.reload();
};

export const Menu = ({ isDark }) => {
  const toast = useToast();
  const { connect, disconnect, state, dispatch } = useWalletConnect(web3Modal);
  const [, setWeb3Account] = useWeb3Account();
  const { provider, address, chainId } = state;
  const chainData = getChainData(chainId);

  setWeb3Account(state);

  const [isBinanceNetwork, setIsBinanceNetwork] = useState();

  // Auto connect to the cached provider
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connect();
    }
  }, [connect]);

  // A `provider` should come with EIP-1193 events. We'll listen for those events
  // here so that when a user switches accounts or networks, we can update the
  // local React state with that new information.
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        dispatch({
          type: "SET_ADDRESS",
          address: accounts[0],
        });
        callCustomToast(
          ACCOUNT_CHANGED,
          ACCOUNT_CHANGED_DESCRIPTION,
          STATE_INFO,
          refreshWindow,
          toast
        );
      };

      const handleChainChanged = (accounts) => {
        dispatch({
          type: SET_ADDRESS,
          address: accounts[0],
        });
        callCustomToast(
          NETWORK_CHANGED,
          NETWORK_CHANGED_DESCRIPTION,
          STATE_INFO,
          refreshWindow,
          toast
        );
      };

      const handleDisconnect = (error) => {
        console.log(DISCONNECT_EVT, error);
        disconnect();
      };

      provider.on(ACCOUNTS_CHANGED_EVT, handleAccountsChanged);
      provider.on(CHAIN_CHANGED_EVT, handleChainChanged);
      provider.on(DISCONNECT_EVT, handleDisconnect);

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener(ACCOUNTS_CHANGED_EVT, handleAccountsChanged);
          provider.removeListener(CHAIN_CHANGED_EVT, handleChainChanged);
          provider.removeListener(DISCONNECT_EVT, handleDisconnect);
        }
      };
    }
  }, [provider, disconnect]);

  const onClickHandler = async (evt) => {
    try {
      let res = await connect();
      setWeb3Account(res);
      console.log({ res });
    } catch (error) {
      const capturedError = new Error(COULDNT_OPEN_MODAL_ERROR);
      Sentry.captureException(capturedError);
    }
  };

  const onClickDisconnect = async () => {
    disconnect();
  };

  const isChainUnsupported = (chainId) => {
    if (!supportedChains.includes(chainId)) {
      callCustomToast(
        UNSUPPORTED_NETWORK,
        UNSUPPORTED_NETWORK_DESCRIPTION,
        STATE_WARNING,
        null,
        toast
      );
    }
  };

  return (
    <Flex
      bg={isDark ? "#080e40" : "#F7FAFC"}
      width="100%"
      align="center"
      justify="space-between"
      wrap="wrap"
      maxW="1280px"
      p={4}
    >
      <MotionBox
        whileHover={{
          scale: 1.4,
          rotate: 360,
        }}
      >
        <a href="https://memecoingen.com">
          <img
            src={
              isDark
                ? "/memecoingen_logo.svg"
                : "/memecoingen_logo_dark_txt.svg"
            }
            width="90px"
          />
        </a>
      </MotionBox>

      <Box
        textAlign="center"
        px={[4, 2, 0, 0]}
        pt={4}
        order={[2, 2, 0, 0]}
        display={["flex", "flex", "initial", "initial"]}
        justifyContent={"center"}
        width={["100%", "initial", "initial", "initial"]}
      >
        <Box mr={1} as="span" color="white">
          Works best with{" "}
        </Box>{" "}
        <Box as="a" href="https://metamask.io" fontWeight="bold" color="orange">
          {" "}
          MetaMask
        </Box>{" "}
        <Image
          ml={2}
          display="inline-block"
          w="24px"
          src="/metamask.svg"
        ></Image>
      </Box>
      <Box display="flex" flexDirection="row">
        <Button
          py={2}
          px={4}
          borderRadius="10px"
          fontWeight="600"
          variant="outline"
          color="white"
          _hover={{
            background: "white",
            color: "black",
            cursor: "pointer",
          }}
          flexDir="row"
          display="flex"
          onClick={onClickHandler}
          disabled={address}
        >
          {address && chainData.chain_id == 56 && (
            <Image
              display="inline"
              alignSelf="center"
              src="/binance-coin-bnb-logo.png"
              w="18px"
              h="18px"
              mr={2}
            />
          )}
          {address && chainData.chain_id == 1 && (
            <Image
              display="inline"
              alignSelf="center"
              src="/ethereum-eth.svg"
              w="20px"
              h="20px"
              mr={2}
            />
          )}

          {address && !supportedChains.includes(chainData.chain_id) && " ⁉️ "}

          {address ? getEllipsisAddress(address) : "Connect Wallet"}
        </Button>

        {address && (
          <Button
            as="a"
            py={2}
            px={4}
            ml={4}
            borderRadius="10px"
            fontWeight="600"
            variant="outline"
            color="white"
            _hover={{
              background: "white",
              color: "black",
              cursor: "pointer",
            }}
            flexDir="row"
            display="flex"
            onClick={onClickDisconnect}
          >
            👋
          </Button>
        )}
        {/* {isBinanceNetwork === false && (
          <Button
            ml={4}
            as="a"
            py={2}
            px={4}
            borderRadius="10px"
            fontWeight="600"
            color="black"
            bg="gold"
            clipPath="fill-box"
            _hover={{
              background: "white",
              color: "black",
              cursor: "pointer",
            }}
            flexDir="row"
            display="flex"
          >
            Switch to Binance Network
          </Button>
        )} */}
      </Box>
    </Flex>
  );
};

export default Menu;
