import supportedChains from "./chains";

export function getChainData(chainId) {
  if (!chainId) {
    return null;
  }
  const chainData = supportedChains.filter(
    (chain) => chain.chain_id === chainId
  )[0];

  if (!chainData) {
    throw new Error("ChainId missing or not supported");
  }

  const API_KEY = "24482bb6d9f14948a7cd9528749367b9";
  // 570663748de34f8894565a05570f7b33
  if (
    chainData.rpc_url.includes("infura.io") &&
    chainData.rpc_url.includes("%API_KEY%") &&
    API_KEY
  ) {
    const rpcUrl = chainData.rpc_url.replace("%API_KEY%", API_KEY);

    return {
      ...chainData,
      rpc_url: rpcUrl,
    };
  }

  return chainData;
}

export function getEllipsisAddress(address = "", width = 4) {
  if (!address) {
    return "";
  }
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}

export const showToast = (
  toast,
  title,
  description,
  status,
  onCloseComplete = null
) => {
  toast({
    title,
    description,
    status,
    duration: 9000,
    position: "top",
    isClosable: true,
    onCloseComplete,
  });
};
