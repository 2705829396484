export const RESET_WEB3_PROVIDER = "RESET_WEB3_PROVIDER";
export const SET_WEB3_PROVIDER = "SET_WEB3_PROVIDER";

// Toasts
export const ACCOUNT_CHANGED = "Account Changed";
export const ACCOUNT_CHANGED_DESCRIPTION =
  "The account address has been changed, the window will refresh with the new details when this notification closes";
export const STATE_INFO = "info";

export const SET_ADDRESS = "SET_ADDRESS";
export const NETWORK_CHANGED = "Network Changed";
export const NETWORK_CHANGED_DESCRIPTION =
  "The network has been changed. The page will refresh the app with the new network details when this notification closes";

// EVENTS
export const DISCONNECT_EVT = "disconnect";
export const ACCOUNTS_CHANGED_EVT = "accountsChanged";
export const CHAIN_CHANGED_EVT = "chainChanged";

export const COULDNT_OPEN_MODAL_ERROR =
  "Could not open the connect modal successfully";

export const UNSUPPORTED_NETWORK = "Unsupported Network";
export const UNSUPPORTED_NETWORK_DESCRIPTION =
  "You are on an unsupported network. Please change it to Binance SmartChain (BSC) or (ETH)";

export const STATE_WARNING = "warning";
